<template>
  <div>
    <el-dialog :visible.sync='drawer' v-bind='$attrs' v-on='$listeners'
               :title='title' width='1200px' center append-to-body>
      <div>
        <el-form inline v-model='searchCondition' size='mini'>
          <el-form-item>
            <el-date-picker
              v-model='searchCondition.year'
              type='year'
              format='yyyy年'
              value-format='yyyy'
              placeholder='选择年度' @change='handleQuery' style='width: 140px'>
            </el-date-picker>
          </el-form-item>
          <el-form-item v-if="searchCondition.type!==3">
            <el-select v-model='searchCondition.quarter' placeholder='选择季度' @change='handleQuery'
                       style='width: 175px'
                       clearable>
              <el-option :label='quarter.label' :key='index' :value='quarter.value'
                         v-for='(quarter,index) in quarters'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-else>
            <el-select v-model='searchCondition.quarter' placeholder='选择年度' @change='handleQuery'
                       style='width: 175px'
                       clearable>
              <el-option :label='quarter.label' :key='index' :value='quarter.value'
                         v-for='(quarter,index) in quarterYear'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <DeptSelect ref='refCompanySelect' :multiple='true' @handleSelect='selectDept'
                        :isCheckStrictly="searchCondition.type!==1" style='width: 360px'></DeptSelect>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='handleQuery' :disabled='loading' :loading='loading'>查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button icon='el-icon-plus' @click='handleAdd' :disabled='loadingAdd' v-hasAuthority="'pa_kpi_dept__add'"
                       :loading='loadingAdd'>添加
            </el-button>
          </el-form-item>
        </el-form>
        <div>
          <div class='default-table'>
            <el-table header-align="center" :data='dataList' @sort-change='changeTableSort' max-height='500' stripe highlight-current-row>
              <el-table-column  label='序号' type='index' width='55'></el-table-column>
              <el-table-column label='时间' prop='year' sortable='custom'>
                <template slot-scope='{row}'>

                  <span v-if="searchCondition.type!==3">{{ row.year }} 年第 {{ row.quarter }} 季度</span>
                  <span v-else>
                    {{searchCondition.year}} 年 {{ searchCondition.quarter === 1 ? '上半年' : '下半年'}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label='中心部门' prop='mcn_dept_name' show-overflow-tooltip>
                <template slot-scope='{row}'>
                  <span>{{ row.center_dept_name ? row.center_dept_name : '/' }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="searchCondition.type===1||searchCondition.type===2" label='部门'
                               prop='up_dept_name' show-overflow-tooltip>
                <template slot-scope='{row}'>
                  <span>{{ row.dept_name ? row.dept_name : '/' }}</span>
                </template>
              </el-table-column>
              <el-table-column label='项目组' v-if="searchCondition.type===1" prop='dept_name' sortable='custom'
                               show-overflow-tooltip></el-table-column>
              <el-table-column label='添加时间' prop='created_at' show-overflow-tooltip></el-table-column>
              <el-table-column label='修改时间' prop='updated_at' show-overflow-tooltip></el-table-column>
              <el-table-column label='操作' width='100'>
                <template slot-scope='{row}'>
                  <el-link v-hasAuthority="'pa_kpi_dept__del'" type='danger' icon='el-icon-close'
                           @click='handleRemove(row)'></el-link>
                </template>
              </el-table-column>
            </el-table>
            <div style='display: flex;flex-direction: row;justify-content: space-between;gap: 10px'>
              <div style='padding: 5px;'>
                <el-tooltip placement='right' effect='light'>
                  <div slot='content'>
                    1、考核项目组：继承上季度（包括手动添加的考核部门（比如，电商1-1））<br />
                    2、考核红人：继承上季度（若上季度未设置考核红人，则读取当前项目组下的红人）<br />
                    3、考核项：根据红人考核设置，生成本季度考核项（考核设置继承自上季度的考核配置）
                  </div>
                  <el-button @click='handleInit' type='warning' size='mini' :disabled='loadingInit'
                             v-hasAuthority="'pa_kpi_dept__init'" :loading='loadingInit'>初始化
                  </el-button>
                </el-tooltip>

<!--                <el-button @click='handleSyncLastSetting' plain type='warning' size='medium' :disabled='loadingSync'-->
<!--                           :loading='loadingSync'>同上季度-->
<!--                </el-button>-->
              </div>

              <m-pagination @pagination='getList' :limit.sync='pageData.page_size' :page.sync='pageData.current_page'
                            :total.sync='total'></m-pagination>
            </div>
            <div style='display: none'>
              PS：考核对象（部门）自下而上的配置，有点应该改为自上而下的配置更加合理
            </div>

          </div>

        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import DeptSelect from './DeptSelect'
import SelectManager from '@/pages/pa/components/SelectManager'
import EditManager from '@/pages/pa/components/EditManager'

export default {
  name: 'PaDeptInit',
  components: { EditManager, SelectManager, DeptSelect },
  data() {
    return {
      drawer: false,
      loading: false,
      loadingAdd: false,
      loadingInit: false,
      loadingSync: false,
      direction: 'rtl',
      quarters: [
        // { label: '未选季度', value: 0 },
        { label: '第一季度', value: 1 },
        { label: '第二季度', value: 2 },
        { label: '第三季度', value: 3 },
        { label: '第四季度', value: 4 }
      ],
      quarterYear: [
        {label: '上半年', value: 1},
        {label: '下半年', value: 2},
      ],
      searchCondition: {type: 2, year: null, quarter: null, depts: null},
      checkList: [],
      title: '初始化季度绩效目标',
      dataList: [],
      pageData: { current_page: 1, page_size: 10 },
      total: 0,
      orderBy: { year: 'desc', quarter: 'desc' },
      options: [],
    }
  },
  methods: {
    show(q) {
      this.searchCondition = {type: q.type, year: q.year, quarter: q.quarter, depts: null}
      this.drawer = true
      this.handleQuery()
    },
    handleSearchCondition() {
      let cond = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field])
          cond[field] = this.searchCondition[field]
      })
      return cond
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderBy = orderSort
      this.getList()
    },
    handleAdd() {
      if (!this.searchCondition.year) {
        this.$notify.warning('年份必选')
        return false
      }
      if (!this.searchCondition.quarter) {
        this.$notify.warning('季度必选')
        return false
      }
      if (!this.searchCondition.depts) {
        this.$notify.warning('部门必选')
        return false
      }
      if (this.searchCondition.depts?.length === 0) {
        this.$notify.warning('部门必选')
        return false
      }

      let msg = `确认添加“${this.searchCondition.year}年第${this.searchCondition.type !== 3 ? `${this.searchCondition.quarter}季度` : `${this.searchCondition.quarter === 1 ? '上半年' : '下半年'}度`}，${this.searchCondition.depts.length}个部门`

      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(async () => {
        this.loadingAdd = true
        await this.$api.addPaKpiDeptData(this.searchCondition)
        this.handleQuery()
        this.loadingAdd = false
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消'
        // });
      })
    },

    async getList() {
      this.loadingStatus = true
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData, { orderBy: this.orderBy })
        let {list, pages} = await this.$api.getPaKpiDeptList(searchCondition)
        this.dataList = list || []
        this.pageData.page_size = pages.page_size || 10
        this.pageData.current_page = pages.current_page || 1
        this.total = pages.total || 0


    },
    selectDept(nodeIds) {
      this.searchCondition.depts = nodeIds
    },
    handleRemove(row) {
      let posIndex = this.dataList.indexOf(row)
      let msg = `确认移除考核项目组：“${row.dept_name}”，移除后后续将不参与考核？”`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(async () => {
        this.dataList.splice(posIndex, 1)
        await this.$api.deletePaKpiDeptData({id: row.id})
        await this.handleQuery()
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消'
        // });
      })
    },

    async handleSave() {
      if (!this.formData.manager_id) {
        this.$notify.info('总监必选')
        return
      }
      if (!this.formData.dept_id) {
        this.$notify.info('部门必选')
        return
      }
      let msg = `确认添加要考核的MCN中心部门：“${this.formData.dept_name}”，指定其总监为：“${this.formData.manager_name}”`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(async () => {
        this.loading = true
        await this.$api.savePaDeptSetting(this.formData)
        this.showAddBox = false
        this.loading = false
        this.$emit('saved')
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消'
        // });
      })
    },
    handleInit() {
      if (!this.searchCondition.year) {
        this.$notify.info('年份必选')
        return
      }
      if (!this.searchCondition.quarter) {
        this.$notify.info('季度必选')
        return
      }
      let data = {
        type: this.searchCondition.type,
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter || this.searchCondition.date_value
      }
      let msg = `确认初始化-考核绩效目标：“${this.searchCondition.year}年第${this.searchCondition.quarter}季度”`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(async () => {
        this.loadingInit = true
        let id = await this.$api.initPaDeptSetting(data)
        if (id) {
          await this.getList()
          this.$notify.success('已发起初始化')
        }
        this.loadingInit = false
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消'
        // });
      })
    },
  }
}
</script>

<style scoped>

</style>
