
// 防抖函数工具 (utils/debounce.js)
export function debounce(fn, delay = 300) {
    let timer = null

    return function (...args) {
        // 每次调用都清除之前的定时器
        clearTimeout(timer)

        // 保留正确的 this 上下文
        const context = this

        // 设置新的定时器
        timer = setTimeout(() => {
            fn.apply(context, args)
        }, delay)
    }
}