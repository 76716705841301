<template>
  <div style='display: flex;flex-direction: row;justify-content:flex-start;gap: 1px'>
    <el-cascader
        v-if="isShow"
        style='width: 100%'
        v-bind='$attrs'
        v-on='$listeners'
        v-model='value'
        :options='options'
        :placeholder='placeholder'
        :props="optionsProps"
        @change='handleChange'
    ></el-cascader>
    <el-button v-else class="loadingSpan" v-loading="!isShow">
      {{ placeholder }}
    </el-button>
    <!--    <span v-else>该月暂无项目组发布绩效目标</span>-->
    <el-link icon='el-icon-refresh' @click='getOptions'></el-link>
  </div>
</template>

<script>
export default {
  name: 'DeptSelectTree',
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    },
    isCheckStrictly:{
      type: Boolean,
      default() {
        return true
      }
    },
    isType: {
      type: String,
      default() {
        return 'target'
      }
    }
  },
  computed: {
    placeholder() {
      if (this.searchCondition.type === 1) {
        return `请选择部门（${this.searchCondition.year}年第${this.searchCondition.month || this.searchCondition.quarter}月）`
      } else if (this.searchCondition.type === 2) {
        return `请选择部门（${this.searchCondition.year}年第${this.searchCondition.quarter}季度）`
      } else
        return `请选择部门（${this.searchCondition.year}年${this.searchCondition.quarter === 1 ? '上半年' : '下半年'}）`
    }
  },
  data() {
    return {
      value: null,
      data: [],
      options: [],
      firstOption: {},
      optionsProps: {},
      isShow: false
    }
  },
  methods: {
    show() {
      this.getOptions()

    },
    async getOptions() {
      this.isShow = false
      this.optionsProps = {}
      let quarter = null
      quarter = this.searchCondition.quarter ? this.searchCondition.quarter : this.searchCondition.date_value
      if (this.searchCondition.year && quarter) {
        // this.$notify.info(`${this.searchCondition.year}年 第${this.searchCondition.quarter}季度`)
        let {list} = await this.$api.getMcnDeptSettingOptions({
          year: this.searchCondition.year,
          quarter: quarter,
          type: this.searchCondition.type,
          page_name: this.isType
        })
        this.data = list || []
      } else {
        this.data = []
      }
      this.calcOptions()

    },
    calcOptions() {

      if (this.data.length > 0) {

        this.options = this.handleTree(this.data, 'dpt_id', 'parent_id', 'children', '1')
        // console.log(this.data)

        let dept = this.options[0]
        //计算第一个项目组
        this.calcLastDept(dept)
        this.optionsProps = {
          ...{
            expandTrigger: 'hover',
            multiple: false,
            emitPath: false,
            checkStrictly: this.isCheckStrictly,
            value: 'dpt_id',
            label: 'name',
          }

        }

      } else {
        this.options = [...[]]

        this.searchCondition['dept_id'] = null
        let searchCondition = {
          ...this.searchCondition,
          dept_id: null,
          group_name: null
        }
        this.$emit('update:searchCondition', searchCondition)

      }
      setTimeout(() => {
        this.isShow = true

      }, 500)

    },
    getNodeName(val) {
      let obj = this.data.find(value => value.dpt_id === val)
      return obj ? obj.name : ''
    },
    getNodeKpiId(val) {
      let obj = this.data.find(value => value.dpt_id === val)
      return obj ? obj.kpi_dept_id : ''
    },
    handleChange(val) {
      const dept_name = this.getNodeName(val)
      const getNodeKpiId = this.getNodeKpiId(val)
      this.$emit('handleSelect', {dept_id: val, dept_name: dept_name, kpi_dept_id: getNodeKpiId})
      return val
    },
    calcLastDept(dept) {
      // console.log(dept)
      if (dept && dept['children']) {
        let firstChildDept = dept['children'][0]
        if (firstChildDept) {
          this.calcLastDept(firstChildDept)
        }
      } else {
        //单选
        this.value = dept.dpt_id || null
        // let deptSelect = this.data.find(value => value.dpt_id === this.value)
        // console.log(deptSelect)
        this.$emit('handleSelect', {dept_id: dept.dpt_id, dept_name: dept.name, kpi_dept_id: dept.kpi_dept_id})
      }
    }
  },

}
</script>

<style scoped>
.loadingSpan {
  width: 100%;
  height: 32px;
}
</style>
