import { render, staticRenderFns } from "./directorIndex.vue?vue&type=template&id=0adae6df&scoped=true"
import script from "./directorIndex.vue?vue&type=script&lang=js"
export * from "./directorIndex.vue?vue&type=script&lang=js"
import style0 from "./directorIndex.vue?vue&type=style&index=0&id=0adae6df&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0adae6df",
  null
  
)

export default component.exports